import { Link } from "gatsby"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Heading from "../components/common/heading"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import "./error-404.scss"

const NotFoundPage = ({ pageContext }) => {
  const intl = useIntl()
  const isSSR = typeof window === "undefined"

  const seoData = setSeoData({
    lang: intl.locale,
    title: "404",
    field_seo: {},
  })

  let link

  if (seoData.title === "404" && !isSSR) {
    const elemID = document.getElementById("mast")
    elemID.classList.add("page404")
  }

  // Set homepage link
  switch (intl.locale) {
    case "fr":
      link = "/fr"
      break
    default:
      link = "/"
  }

  return (
    <Layout pageClass="page404" pageContext={pageContext}>
      <Seo data={seoData} />
      <section className="blk-404">
        <Container>
          <Row>
            <Col>
              <div className="error-info">
                <Heading
                  title={intl.formatMessage({ id: "404.title" })}
                  description={intl.formatMessage({ id: "404.description" })}
                />
                <Link to={link}>
                  {intl.formatMessage({ id: "404.link_text" })}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default NotFoundPage
